import InCyberHeaderCtf from "@/ctf-components/incyber-header";
import InCyberHeaderMobile from "@/ctf-components/incyber-header/mobile-header";


const InCyberHeader = () => {
  return (
    <header className="sticky top-0 bg-white z-50 container mx-auto py-3 md:py-6 ">
      <div className="HeaderNavigation flex justify-between items-center max-[1150px]:hidden">
        <InCyberHeaderCtf />
      </div>
      <div className="flex min-[1151px]:hidden">
        <InCyberHeaderMobile />
      </div>
    </header>
  );
};

export default InCyberHeader;
