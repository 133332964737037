import { fetchConfig } from '@/lib/fetchConfig';
import { useQuery } from '@tanstack/react-query';

export const queryInCyberFooter = `query ($locale: String!) {
  footerCollection(limit: 1, locale: $locale, where: { internalTitle: "InCyber footer" }) {
    __typename
    items {
      __typename
      ...on Footer {
        title
        socialLinksCollection {
          items {
            ...on NavigationElement {
              title
              urlPath
              document {
                title
                url
                width
                height
              }
            }
          }
        }
        footerNavigationCollection(limit: 20) {
          items {
            ...on NavigationElement {
              title
              urlPath
              document {
                title
                description
                url
                width
              }
            }
          }
        }
        locationsCollection(limit: 20) {
          items {
            ...on NavigationElement {
              title
              urlPath
            }
          }
        }
        consulteer
        backToTop {
          ...on NavigationElement {
            sys {
              id
            }
            title
            urlPath
            document {
              title
              url
              width
              height
            }
          }
        }
      }
    }
  }
}`;

const fetchInCyberFooterData = async (locale = 'de') => {
  const variables = { locale };
  const res = await fetch(fetchConfig.endpoint as string, {
    method: 'POST',
    ...fetchConfig.params,
    body: JSON.stringify({ query: queryInCyberFooter, variables })
  });
  const json = await res.json();

  if (json.errors) {
    const { message } = json.errors[0];

    throw new Error(message);
  }

  return json.data;
};

const useInCyberFooterData = (locale: any) => {
  return useQuery({
    queryKey: ['incyberfooter', locale],
    refetchOnWindowFocus: false,
    queryFn: () => fetchInCyberFooterData(locale)
  });
};

export { useInCyberFooterData, fetchInCyberFooterData };
