import { fetchConfig } from '@/lib/fetchConfig';
import { useQuery } from '@tanstack/react-query';

export const inCyberHeaderQuery = `query($locale: String!) {
  headerCollection(limit: 1, locale: $locale, where: {internalName: "InCyber Header navigation"} ){
   __typename
   items{
     __typename
     ...on Header{
       internalName
       menuItemsCollection(locale: $locale){
         items{
           __typename
           ...on MenuGroup{
             __typename
             internalTitle
             groupName
             sys{
              id
              }
             groupLink{
               ...on NavigationElement{
                 title
                 urlPath
                 __typename
               }
             }
             containerForCategoryLink{
              internalTitle
              title
              linkIconTitle
              shortDescription{
                json
              }
              linkUrl
              icon{
                url
                width
                height
                size
                description
              }
              __typename
            }
           }
         }
       }
     }
   }
 }
}`;

const fetchInCyberHeaderNavigation = async (locale = 'de') => {
  const variables = { locale };
  const res = await fetch(fetchConfig.endpoint as string, {
    method: 'POST',
    ...fetchConfig.params,
    body: JSON.stringify({ query: inCyberHeaderQuery, variables })
  });
  const json = await res.json();

  if (json.errors) {
    const { message } = json.errors[0];

    throw new Error(message);
  }
  return json.data;
};

const useInCyberHeaderNavigation = (locale: any) => {
  return useQuery({
    queryKey: ['incyberheader', locale],
    refetchOnWindowFocus: false,
    queryFn: () => fetchInCyberHeaderNavigation(locale)
  });
};

export { useInCyberHeaderNavigation, fetchInCyberHeaderNavigation };
