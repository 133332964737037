import { useContentfulContext } from '@/contentful-context';
import LinkCtf from '@/ctf-components/link/link';
import { useInCyberFooterData } from '@/hooks/useInCyberFooter';
import { LinkIC } from '@/lib/interface-types';
import ArrowUp from '@/public/icons/Dropdown.svg';
import HeartIcon from '@/public/icons/heart-footer.svg';
import Link from 'next/link';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import InstagramIconGreen from '../../public/icons/instagram-green.svg';
import InstagramIcon from '../../public/icons/instagram-video.svg';
import LinkedinIconGreen from '../../public/icons/linkedin-green.svg';
import LinkedinIcon from '../../public/icons/linkedin-white-bg.svg';
import YoutubeIconGreen from '../../public/icons/youtube-green.svg';
import YoutubeIcon from '../../public/icons/youtube-video.svg';
import ContentfulImage from '../contentful-image';

const InCyberFooter = () => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const { locale } = useContentfulContext();
  const { data, isLoading } = useInCyberFooterData(locale);
  const footerData = data?.footerCollection.items[0];
  const footerTitle = footerData?.title;
  const footerNavigation = footerData?.footerNavigationCollection?.items;
  const locations = footerData?.locationsCollection.items;
  const midIndex = footerNavigation && Math.ceil(footerNavigation.length / 2);
  const firstColumn = footerNavigation?.slice(0, midIndex);
  const secondColumn = footerNavigation?.slice(midIndex);
  const pdfLink = footerNavigation?.filter(
    (e: { document: LinkIC }) => e.document
  )[0].document.url;

  const coloredTitle = true;
  const { t } = useTranslation();

  if (isLoading) return <div>Loading</div>;

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const currentYear = new Date().getFullYear();

  const greenTitle = coloredTitle ? footerTitle?.split(' ').pop() : null;
  const blackTitle =
    coloredTitle && greenTitle
      ? footerTitle.replace(greenTitle, ' ')
      : footerTitle;

  const socialMediaLinks = [
    {
      href: 'https://www.instagram.com/consulteer/',
      icon: InstagramIcon,
      hoverIcon: InstagramIconGreen
    },
    {
      href: 'https://www.linkedin.com/company/consulteer/',
      icon: LinkedinIcon,
      hoverIcon: LinkedinIconGreen
    },
    {
      href: 'https://www.youtube.com/@Consulteer',
      icon: YoutubeIcon,
      hoverIcon: YoutubeIconGreen
    }
  ];

  return (
    <footer className="bg-brand-deep-blue border-brand-green border-[20px] mt-20">
      <div className="container mx-auto xl:py-18 md:py-12 text-white mt-6 lg:mt-0 mb-4">
        <div className="flex gap-8 flex-col md:flex-row justify-between items-baseline">
          <h2 className="lg:w-[670px] sm:w-100 text-white mt-0">
            {blackTitle} <span className="text-brand-green">{greenTitle}</span>
          </h2>
          <ContentfulImage src={HeartIcon} width={60} height={60} />
        </div>
        <div className="lg:flex sm:block justify-between mt-6 text-base">
          <div className="mt-10 grid grid-cols-1 md:grid-cols-2 gap-6 pb-5 gap-x-20 uppercase">
            <div className="grid grid-cols-1 gap-6">
              {firstColumn &&
                firstColumn.map((link: LinkIC) =>
                  link?.document ? (
                    <div key={uuid()}>
                      <LinkCtf link={link} target pdfLink={pdfLink} />
                    </div>
                  ) : (
                    <div key={uuid()}>
                      <LinkCtf link={link} />
                    </div>
                  )
                )}
            </div>

            <div className="grid grid-cols-1 gap-6 text-shades-white">
              {secondColumn &&
                secondColumn.map((link: LinkIC) => (
                  <div key={uuid()}>
                    <LinkCtf link={link} />
                  </div>
                ))}
            </div>
          </div>

          <div className="mt-10 ml-auto grid grid-flow-col grid-rows-5 md:grid-rows-3 gap-6 gap-x-20  uppercase">
            {locations &&
              locations.map((link: LinkIC) => (
                <div key={uuid()}>
                  <LinkCtf target link={link} />
                </div>
              ))}
          </div>
        </div>
        <div className="flex flex-col w-fit sm:flex-row mt-16">
          <Link
            className="green-button px-6 py-3 mr-10 lg:mr-20 mb-2 ml-2"
            href="/contact"
          >
            {t('footer.contact')}
          </Link>
          <div className="flex mt-10 sm:mt-0 gap-x-5 items-center">
            {socialMediaLinks.map((link, index) => (
              <div
                key={index}
                className="pr-2"
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <Link href={link.href} target="_blank">
                  <ContentfulImage
                    src={index === hoveredIndex ? link.hoverIcon : link.icon}
                    alt="Social media icon"
                    width={link.icon === YoutubeIcon ? 50 : 40}
                    height={40}
                  />
                </Link>
              </div>
            ))}
          </div>
        </div>
        <div className="flex items-center justify-between mt-10">
          <div className="text-shades-white text-base">
            © {currentYear} Consulteer
          </div>
          <div className="uppercase font-bold flex hover:text-brand-green">
            <button
              type="button"
              onClick={scrollToTop}
              className="transition-all uppercase scroll-smooth text-brand-green"
            >
              {t('mobile_menu.back_to_top')}
            </button>
            <div className="ml-1 mb-1">
              <ContentfulImage src={ArrowUp} width={20} height={20} />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default InCyberFooter;
