import { LanguageSelector } from '@/components/language-selector';
import { useInCyberHeaderNavigation } from '@/hooks/useInCyberNavigation';
import { MenuGroupIC } from '@/lib/interface-types';
import LogoImage from '@/public/incyber-logo.svg';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import uuid from 'react-uuid';
import NavigationItem from '../header/navigation-item';

const InCyberHeaderCtf = () => {
  const { locale } = useRouter();
  const { data, isLoading } = useInCyberHeaderNavigation(locale);
  const { items } = data?.headerCollection?.items[0]?.menuItemsCollection || [];
  const [openSubMenuIndex, setOpenSubMenuIndex] = React.useState(null);

  const closeOtherMenus = () => {
    setOpenSubMenuIndex(null);
  };

  if (isLoading) return <div>Loading</div>;

  return (
    <div className="HeaderCtf w-full flex flex-row items-center justify-between">
      <Link href="/">
        <Image src={LogoImage} alt="Home page" width={214} height={60} />
      </Link>

      <div className="flex flex-row">
        <nav className="HeaderNavigation flex flex-row items-center mt-[8px]">
          {items &&
            items.map((groupItem: MenuGroupIC, index: number) => (
              <NavigationItem
                groupItem={groupItem}
                key={uuid()}
                closeOtherMenus={closeOtherMenus}
                isOpen={openSubMenuIndex === index}
              />
            ))}
        </nav>
        <LanguageSelector mobile={false} />
      </div>
    </div>
  );
};

export default InCyberHeaderCtf;
